var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "企业通讯录",
            width: "500px",
            "show-close": false,
            "custom-class": "staff-dialog",
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "tc mb10", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.table,
                    callback: function ($$v) {
                      _vm.table = $$v
                    },
                    expression: "table",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "left" } }, [
                    _vm._v("按部门筛选"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "search",
            attrs: { type: "text", placeholder: "搜索人员" },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ova" }, [
            _vm.table === "left"
              ? _c(
                  "div",
                  { staticStyle: { "text-align": "left" } },
                  [
                    _vm.radio
                      ? _c(
                          "el-checkbox-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.radioFix()
                              },
                            },
                            model: {
                              value: _vm.checkList,
                              callback: function ($$v) {
                                _vm.checkList = $$v
                              },
                              expression: "checkList",
                            },
                          },
                          _vm._l(_vm.arr, function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "f14 pl50 pt10 g6 bbe scrollDiv",
                                    staticStyle: {
                                      width: "100%",
                                      "margin-left": "0",
                                      "line-height": "33px",
                                      color: "#409EFF",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.name) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(item.user_arr, function (res) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: res.id,
                                      staticClass: "db bbe",
                                      staticStyle: {
                                        width: "100%",
                                        "margin-left": "20px",
                                        "line-height": "33px",
                                      },
                                      attrs: {
                                        label: res.id,
                                        disabled: res.id == _vm.userId,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "b g0" }, [
                                        _vm._v(_vm._s(res.realname)),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _vm._l(_vm.arr, function (item, i) {
                          return _c("select-all-block", {
                            key: item.name,
                            attrs: { index: i, "staff-list": item },
                            on: { "assign-options": _vm.assignOptions },
                          })
                        }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer bte tl pt10 pb10 pl50",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.disable },
                  on: {
                    click: function ($event) {
                      return _vm.confirm()
                    },
                  },
                },
                [_vm._v("已选" + _vm._s(_vm.checkNum))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }